<template>

  <div class="main">
    <agent-info-comp></agent-info-comp>
    <div class="search">
      <div class="refresh" @click="getUserList"><i class="fa fa-refresh"></i></div>
      <!--파트너 선택-->
      <partner-sub-selector-comp @setPartnerId="setPartnerId"></partner-sub-selector-comp>

      <!--검색조건선택-->
      <el-select size="mini" style="width: 100px;margin-left: 5px" v-model="searchUser.byField"
                 placeholder="검색조건">
        <el-option :value="agentConst.User.SEARCH_BY_USERNAME" label="아이디"></el-option>
        <el-option :value="agentConst.User.SEARCH_BY_NICKNAME" label="닉네임"></el-option>
        <el-option :value="agentConst.User.SEARCH_BY_BENEFICIARY" label="예금주"></el-option>
      </el-select>
      <el-input v-model="searchUser.searchString" size="mini" style="width: 150px;margin-left: 5px"
                placeholder="검색내용입력"></el-input>
      <el-button type="primary" size="mini" style="margin-left: 5px" @click="seachUser">검색</el-button>

      <!--상태-->
      <el-select size="mini" style="width: 80px;margin-left: 5px" v-model="selectedStatus"
                 @change="changeStatus" placeholder="회원상태 선택">
        <el-option :value="agentConst.User.STATUS_ENABLE" label="정상">정상</el-option>
        <el-option :value="agentConst.User.STATUS_DISABLE" label="블럭">블럭</el-option>
      </el-select>

      <el-select size="mini" style="width: 130px;margin-left: 5px" v-model="orderBy"
                 @change="getUserList" placeholder="회원상태 선택">
        <el-option value="id DESC" label="가입순정열">가입순정열</el-option>
        <el-option value="last_login_time DESC,id DESC" label="최근접속정열">최근접속정열</el-option>
        <el-option value="totalrecharge desc" label="총입금정열">총입금정열</el-option>
        <el-option value="totalexchange desc" label="총출금정열">총출금정열</el-option>
        <el-option value="reprofit desc" label="손이익정열(+)">손이익정열(+)</el-option>
        <el-option value="reprofit asc" label="손이익정열(-)">손이익정열(-)</el-option>
        <el-option value="cash DESC,id DESC" label="머니순정열">머니순정열</el-option>
        <el-option value="user_point DESC,id DESC" label="포인트순정열">포인트순정열</el-option>
        <el-option value="beneficiary ASC,id DESC" label="이름정열">이름정열</el-option>
      </el-select>

    </div>

    <table class="agent_table">
      <tr>
        <th>상태</th>
        <th>총판</th>
        <th>아이디</th>
        <th>닉네임</th>
        <th>예금주</th>
        <th>레벨</th>
        <th>보유금액</th>
        <th>보유Point</th>
        <th>카지노칩</th>
        <th>가입일짜</th>
        <th>최근로그인</th>
        <th>막충일</th>
        <th>총입금</th>
        <th>총출금</th>
        <th>손이익</th>
        <th>회원Casino롤링</th>
        <th>회원Slot롤링</th>
        <th>회원Mini롤링</th>
        <th>회원Sports롤링</th>
      </tr>
      <tr v-for="item in userList">
        <td>
          <div v-if="item.status == agentConst.User.STATUS_ENABLE">[정상]</div>
          <div style="color: red" v-if="item.status == agentConst.User.STATUS_DISABLE">
            [블럭]
          </div>
          <div style="color: #b3d8ff"
               v-if="item.status == agentConst.User.STATUS_UNAUTHORIZED">[인증]
          </div>
        </td>
        <td>
          <div v-if="null != item.agent" style="cursor: pointer">
            {{ item.agent.nickname }}
          </div>
        </td>
        <td style="cursor: pointer">
          <router-link tag="span"
                       :to="{path: '/agent/agent_user_cashpoint_history', query: {nickname: item.nickname}}">
            {{ item.username }}
          </router-link>
        </td>
        <td style="cursor: pointer">
          <router-link tag="span" :to="{path: '/agent/agent_user_cashpoint_history', query: {nickname: item.nickname}}">
            {{ item.nickname }}
          </router-link>
        </td>
        <td>
          {{ item.beneficiary }}
        </td>
        <td>
          {{ item.rank.rankName }}
        </td>
        <td>
          <span>{{ item.cash|comma }}</span>
        </td>
        <td>
          <span>{{ item.userPoint|comma }}</span>
        </td>
        <td>
          <div>{{ item.casinocash|comma }}</div>
          <button class="small primary" @click="getCasinoBalance(item)">칩확인</button>
        </td>
        <td>
          <span>{{ item.createTime|datef('MM월 DD일') }}</span>
        </td>
        <td>
          <span v-if="item.lastLoginTime != null">{{ item.lastLoginTime|datef('MM월 DD일') }}</span>
        </td>
        <td>
          <span v-if="item.lastRechargeTime != null"> {{ item.lastRechargeTime|datef('MM월 DD일') }}</span>
        </td>
        <td class="text-blue">{{ item.totalrecharge|comma }}</td>
        <td class="text-red">
          {{ (item.totalexchange * (-1))|comma }}
        </td>
        <td>{{ (item.totalrecharge - item.totalexchange)|comma }}</td>
        <td>
          <el-input v-model="item.casinobetGivebackPersent" size="mini" style="width: 60px"></el-input>
          <el-button type="primary" size="mini" @click="updateShareUserProfit(item)">수정</el-button>
        </td>
        <td>
          <el-input v-model="item.slotbetGivebackPersent" size="mini" style="width: 60px"></el-input>
          <el-button type="primary" size="mini" @click="updateShareUserProfit(item)">수정</el-button>
        </td>
        <td>
          <el-input v-model="item.leisurebetGivebackPersent" size="mini" style="width: 60px"></el-input>
          <el-button type="primary" size="mini" @click="updateShareUserProfit(item)">수정</el-button>
        </td>
        <td>
          <el-input v-model="item.sportsbetGivebackPersent" size="mini" style="width: 60px"></el-input>
          <el-button type="primary" size="mini" @click="updateShareUserProfit(item)">수정</el-button>
        </td>
      </tr>
    </table>

    <div class="pagePanel">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageNum"
          :page-sizes="[25,50, 100, 150, 200]"
          :page-size="25"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageTotal">
      </el-pagination>
    </div>
  </div>


</template>

<script>
import OnlineUserComp from "../../components/administrator/OnlineUserComp";
import TodayStatisticComp from "../../components/administrator/TodayStatisticComp";
import ManagerTopbarComp from "../../components/administrator/ManagerTopbarComp";

import {Loading} from "element-ui";
import PartnerSelectorComp from "../../components/administrator/PartnerSelectorComp";

import PartnerSubSelectorComp from "../../components/agent/PartnerSubSelectorComp";
import {agentMixin} from "../../common/agent/agentMixin";
import agentConst from "../../common/agent/agentConst";
import {getAgentById, getCasinoBalance, getUserList2, updateShareUserProfit} from "../../network/agent/commonRequest";
import AgentInfoComp from "../../components/agent/AgentInfoComp";
import {RECEIVE_HIDE_LOADING, RECEIVE_SHOW_LOADING} from "@/store/mutation-types";

export default {
  name: "AgentUser",
  components: {
    AgentInfoComp,
    PartnerSubSelectorComp,
    PartnerSelectorComp,
    ManagerTopbarComp,
    TodayStatisticComp,
    OnlineUserComp
  },
  mixins: [agentMixin],
  data() {
    return {
      user: {
        status: agentConst.User.STATUS_ENABLE,
      },
      searchUser: {},
      pageNum: 1,
      pageSize: 25,
      orderBy: 'id DESC',
      pageTotal: 0,
      userList: [],
      editUserId: 0,
      selectedPartnerId: null,
      selectedType: {},
      selectedRank: null,
      selectedStatus: agentConst.User.STATUS_ENABLE,

    }
  },
  methods: {
    getCasinoBalance(item) {
      this.$store.commit(RECEIVE_SHOW_LOADING)
      getCasinoBalance(item.id).then(res => {
        this.$store.commit(RECEIVE_HIDE_LOADING)
        item.casinocash = res.data.data
      })
    },

    getUserList() {
      this.$store.commit(RECEIVE_SHOW_LOADING)
      getUserList2(this.user, this.pageNum, this.pageSize, this.orderBy).then(res => {
        this.$store.commit(RECEIVE_HIDE_LOADING)
        this.pageTotal = res.data.total;
        this.userList = res.data.data;

      })
    },
    handleSizeChange(val) {
      this.pageNum = 1;
      this.pageSize = val;
      this.getUserList();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getUserList();
    },
    setPartnerId(partnerId) {
      this.pageNum = 1
      this.user = {}
      this.user.status = agentConst.User.STATUS_ENABLE
      this.user.partnerId = partnerId
      this.getUserList()
    },
    seachUser() {
      this.user = this.searchUser
      this.getUserList()
    },
    changeStatus() {
      this.user = {}
      this.user.status = this.selectedStatus
      this.getUserList()
    },
    updateShareUserProfit(user) {
      updateShareUserProfit(user).then(res => {
        if (res.data.success) {
          this.$message.success("변경완료");
          this.getUserList();
        } else {
          this.$message.error(res.data.msg);
        }
      })
    }

  },
  created() {
    this.getUserList()
  },
  watch: {}
}
</script>

<style scoped>

</style>